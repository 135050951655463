#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}



#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


.maindiv{
   
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100vh;
    
}
.mydiv{
    display: flex;
    align-items: center;
    margin: 15px;
}
.logo{
    width: 10rem;
}
.home{
    margin-left: 1rem;
}
.btndiv{
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
}
.logout{
    background: #fff;
    border: 1px solid red;
    border-radius: 4px;
    padding: 10px 20px 10px 20px;
    color: red;
}
.logout:hover{
    border: 1px solid red;
    background: red;
    color: #fff;
}





.agentdiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 15px 0 15px;
}
.userdiv{
    display: flex;
    flex-direction: column;
}
.chits{
    font-size: 1.5rem;
    text-align: left;
}
.usersbtn{
    display: flex;
    flex-direction: row;
}
.adduser{
    width: 100px;
    padding: 10px 0 10px 0;
    border-radius: 18px;
    font-weight: bold;
    border: 2px solid rgb(73, 124, 7);
    color: rgb(73, 124, 7);
    background: #fff;
}
.adduser:hover{
    border: 2px solid rgb(73, 124, 7);
    color: rgb(73, 124, 7);
    background: rgb(218, 250, 175);
}
.viewuser{
    width: 100px;
    margin-left: 1rem;
    padding: 10px 0 10px 0;
    border-radius: 18px;
    font-weight: bold;
    border: 2px solid rgb(73, 124, 7);
    color: rgb(73, 124, 7);
    background: #fff;
}
.viewuser:hover{
    border: 2px solid rgb(73, 124, 7);
    color: rgb(73, 124, 7);
    background: rgb(218, 250, 175);
}
.agentlogodiv{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}
.agentlogo{
    width: 80px;
}





.agentchits{
    display: flex;
    align-items: center;
    margin-top: 25px;
} 
.chitdiv{
    width: 150px;
    padding: 10px;
    margin: 0 0 0 15px;
    background-color: rgb(177, 244, 109);
    border-radius: 10px;
    text-align: left;
    font-size: 0.8rem;
    border: 2px solid rgb(73, 124, 7);
    box-shadow: 0 20px 35px rgb(0, 0, 0, 0.1);
}
.chitbtns{
    display: flex;
    flex-direction: column;
}
.add{
    width: 100px;
    padding: 5px 0 5px 0;
    border-radius: 18px;
    font-weight: bold;
    margin-top: 6px;
    border: 2px solid rgb(90, 152, 8);
    color: #fff;
    background: rgb(90, 152, 8);
}
.add:hover{
    border: 2px solid rgb(90, 152, 8);
    color: rgb(90, 152, 8);
    background: #fff;
}
.account{
    width: 100px;
    margin-top: 10px;
    padding: 5px 0 5px 0;
    border-radius: 18px;
    font-weight: bold;
    border: 2px solid rgb(90, 152, 8);
    color: #fff;
    background: rgb(90, 152, 8);
}
.account:hover{
    border: 2px solid rgb(90, 152, 8);
    color: rgb(90, 152, 8);
    background: #fff;
}



.searchdiv{
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 15px;
}
.searchbar{
    padding: 10px 20px 10px 20px;
    border: 2px solid rgb(73, 124, 7);
    color: rgb(73, 124, 7);
    border-radius: 20px;
}





.tablebox{
    margin: 15px;
}
.tablerow{
    display: table;
    width: 100%;
    margin: 20px auto;
    font-family: sans-serif;
    background: transparent;
    cursor: pointer;
    padding: 5px 0;
    color: #5264FF;
    border-radius: 34px;
    font-size: 13px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}
.tablecell{
    display: table-cell;
    width: 16%;
    text-align: center;
    cursor: pointer;
    padding: 4px 0;
    color: #000;
    border-right: 1px solid #d6d4d4;
    vertical-align: middle;
}
.tablehead .tablecell{
    background: rgb(90, 152, 8);
    border-right: none;
    color: #fff;
    border-radius: 34px;
}
.tablehead{
    background: rgb(90, 152, 8);
    box-shadow: none;
    border-radius: 34px;
    border: 2px solid rgb(73, 124, 7);
    font-weight: 600;
}
.tablehead p{
    background: rgb(90, 152, 8);
}
.lastcell{
    border-right: none;
}
.firstcell{
    text-align: left;
    padding-left: 10px;
}
.tablebtn{
    width: 100px;
    padding: 5px 0 5px 0;
    border-radius: 18px;
    font-weight: bold;
    border: 2px solid rgb(90, 152, 8);
    color: #fff;
    background: rgb(90, 152, 8);
}
.tablebtn:hover{
    border: 2px solid rgb(90, 152, 8);
    color: rgb(90, 152, 8);
    background: #fff;
}